import { type Review, type User } from '@prisma/client'
import { type ServiceWithSessionAndReview } from '~/features/home/professional-profile.tsx'

export interface ReviewWithReviewerId extends Review {
  reviewerId: number
}

export default function getStarsAndReviewDataForProfesional(
  profesional: User & {
    services: ServiceWithSessionAndReview[]
  },
) {
  const reviewsArray: ReviewWithReviewerId[] = profesional.services.flatMap(
    service => {
      return getReviewsFromService(service)
    },
  )

  const starsSummation = reviewsArray.reduce(
    (accumulator, currentReview) => accumulator + currentReview.stars,
    0,
  )

  if (!reviewsArray.length) {
    return { stars: 0, reviews: [] }
  }

  const starsAverage = starsSummation / reviewsArray.length

  return { stars: starsAverage, reviews: reviewsArray }
}

export function getReviewsFromService(service: ServiceWithSessionAndReview) {
  return service.serviceBooked.flatMap(session => {
    if (session.review) {
      return {
        ...session.review,
        reviewerId: session.clientId,
      }
    }
    return []
  })
}

export function getServicesStar(
  services: ServiceWithSessionAndReview[],
): number {
  if (!services.length) {
    return 0
  }
  const reviewsArray: ReviewWithReviewerId[] = services?.flatMap(service => {
    return getReviewsFromService(service)
  })

  const starsSummation = reviewsArray?.reduce(
    (accumulator, currentReview) => accumulator + currentReview.stars,
    0,
  )

  if (!reviewsArray.length) {
    return 0
  }

  const starsAverage = starsSummation / reviewsArray.length

  return starsAverage
}
